<template>
    <section class="product_details">
        <div class="container">
            <div class="dashboard">
                <div class="container-fluid">
                    <div class="row">
                        <div class="profile-top-bar">
                            <div class="dashboard-breadcrumb">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="#">Account Information</a></li>
                                    </ol>
                                </nav>
                                <div class="text-right" style="color: white">
                                    {{ me.customer_name }}
                                </div>
                                <!--<div class="dropdown">
                                    <button
                                            class="btn btn-secondary dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                    >
                                        {{ me.customer_name }}
                                    </button>
                                    <div
                                            class="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                    >
                                        <a class="dropdown-item" href="#"> Profile</a>
                                        <a class="dropdown-item" href="#"
                                        ><i class="fa fa-sign-out pull-right"></i> Log Out</a
                                        >
                                    </div>
                                </div>-->
                            </div>
                        </div>
                    </div>

                    <div class="row row-flex">
                        <Navbar></Navbar>

                        <div class="col-md-9 eqal-col" style="padding-left: 5px">
                            <div class="dashboard-right">
                                <div class="dashboard-quotation">
                                    <div class="container-fluid" id="dashboard_right">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12">
                                                <div class="premium_plan">
                                                    <div class="plan_box_content">
                                                        <div class="regular_member items">
                                                            <div class="content">

                                                                <h3>Account Information</h3>
                                                                <ul class="list">
                                                                    <li>
                                                                        <i class="icon ion-ios-arrow-dropright"></i>
                                                                        &nbsp;<span>&nbsp; Name: {{ me.customer_name }}</span>
                                                                    </li>
                                                                    <li>
                                                                        <i class="icon ion-ios-arrow-dropright"></i>
                                                                        &nbsp;<span>&nbsp; Address:
                                                                      {{
                                                                        me.customer_address &&
                                                                        me.customer_address.customer_address
                                                                      }}
                                                                    </span>
                                                                    </li>
                                                                    <li>
                                                                        <i class="icon ion-ios-arrow-dropright"></i>
                                                                        &nbsp;<span
                                                                    >&nbsp; Area Name:
                                                                      {{
                                                                        me.customer_address &&
                                                                        me.customer_address.area && me.customer_address.area.name
                                                                      }}
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <i class="icon ion-ios-arrow-dropright"></i>
                                                                        &nbsp;<span>&nbsp; Division:
                                      {{
                                        me.customer_address &&
                                        me.customer_address.division && me.customer_address.division.name
                                      }}
                                  </span
                                  >
                                                                    </li>
                                                                    <li>
                                                                        <i class="icon ion-ios-arrow-dropright"></i>
                                                                        &nbsp;<span
                                                                    >&nbsp; District:
                                      {{
                                        me.customer_address &&
                                        me.customer_address.district && me.customer_address.district.name
                                      }}</span
                                                                    >
                                                                    </li>
                                                                    <li>
                                                                        <i class="icon ion-ios-arrow-dropright"></i>
                                                                        &nbsp;<span
                                                                    >&nbsp; Zip:
                                                                        <!--{{
                                                                          me.customer_address &&
                                                                          me.customer_address.zip
                                                                        }}--></span
                                                                    >
                                                                    </li>
                                                                    <li>
                                                                        <i class="icon ion-ios-arrow-dropright"></i>
                                                                        &nbsp;<span
                                                                    >&nbsp; PHone:
                                      {{ me.customer_phone }}</span
                                                                    >
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="premium_member items">
                                                            <div class="content">
                                                                <h3>Premium Customer</h3>
                                                                <ul class="list">
                                                                    <li>
                                                                        <i class="icon ion-ios-arrow-dropright"></i>
                                                                        &nbsp;<span
                                                                    >&nbsp; No watermark on the banner
                                      slider</span
                                                                    >
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import {mapGetters} from "vuex";
    import Navbar from "@/components/frontend/MyDashboard/Navbar.vue";

    export default {
        computed: {
            ...mapGetters(["me", "customerSummery"]),
        },
        components: {
            Navbar,
        },
    };
</script>
<style lang="scss">
    @import "@/assets/frontend/style/dashboard.scss";
    @import "@/assets/frontend/style/details.scss";
    @import "@/assets/frontend/style/bs_leftnavi.scss";
    @import "@/assets/frontend/style/customize.scss";
</style>
